import React from 'react'
import { Layout, Stack } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import {Text} from "theme-ui";
import Figure from "@components/Mdx/Figure";
import {StaticImage} from "gatsby-plugin-image";

const about = props => {
  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='About (under construction)'
          subheader='Mystery Abyss is a blog that deals with more "realistic" cryptozoological creatures that existed or exist in mankind. Think of the Kraken, pterodactyls, or giants. And for that matter the Biblical beasts like the Leviathan, the Behemoth, or the Nephilim.'
        />
      </Stack>
        <Stack>
            <Text variant='p'>
                In addition, explanations are made between all the creatures and questions are asked such as: How old does the earth really exist? Is there a something more going on here? What can still be distinguished from real or fake? This makes it all more logical to grasp.
            </Text>
        </Stack>
        <Stack>
            <Text variant='h2'>
                The name Mystery Abyss
            </Text>
        </Stack>
        <Stack>
            <Text variant='p'>
                What remains so interesting is the unknown. Most are terrified of it and some live for it.
                The deepest parts of the oceans, caves, or crevices. The outbound ways from the earth, planets or universe.
                The void.
            </Text>
        </Stack>
        <Stack>
            <Text variant='p'>
                It could be said that it is a stylistic mistake Mystery Abyss because an abyss is mysterious of itself.
                Yet it feels like the universe is not as mysterious as it appears and yet also paradoxically mysterious....
            </Text>
        </Stack>
        <Stack>
            <Text variant='h2'>
                The Heart/The Earth
            </Text>
        </Stack>
        <Stack>
            <Text variant='p'>
                As much as possible, efforts are made to speak from the heart. That is, neutrality, no choices or
                hierarchy, not one above the other. Because the heart actually weighs less than a feather.
            </Text>
        </Stack>
        <Stack>
            <Figure><StaticImage src="../../content/assets/mystery-abyss-hearth-weight-less-then-a-feather.jpg" alt="An Egyptian hieroglyph called 'Weighing of the Heart Ceremony'."/><figcaption>An Egyptian hieroglyph where Anubis is weighing the hearth against a feather in the Weighing of the Heart Ceremony.</figcaption></Figure>
        </Stack>
        <Stack>
            <Text variant='p'>
                Even then, the THINker can take over because it has entwined the heart. All it does is think thinly.
                Does that mean that the heart is the right brain? Oh no, that's the THINNER. What also doesn't matter at all is lateralization (The dominance of the left or- right brain) and it is definitely not metaphorically.
            </Text>
        </Stack>
        <Stack>
            <Text variant='p'>
            Follow your real heart. ❤️
            </Text>
        </Stack>
    </Layout>
  )
}

export default about
